<template>
  <sdModal
    centered
    type="primary"
    :title="i18n.t(`option.modalTitle`)"
    :visible="data.visible"
    :onCancel="closeModal"
    :width="400"
    class="publish-modal"
  >
    <a-form :model="dataSource" :rules="rules" @finish="sendData" name="buyOption" ref="buyOption">
      <a-alert :message="error.amount[0]" type="error" v-if="error" show-icon style="margin-bottom: 20px" />
      <a-form-item ref="item_type" name="item_type" class="input__wrapper" :label="i18n.t('option.selectType')">
        <a-select v-model:value="dataSource.item_type" size="large" ref="select" :required="true" style="width: 100%">
          <a-select-option v-for="item of offersOptions" :value="item.type" :key="item.type">
            <p>
              <b>{{ item.name }} x{{ item.multiplier }}</b> ({{ item.code }})
            </p>
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item ref="amount" name="amount" class="input__wrapper" :label="i18n.t('start.summ')">
        <a-input
          v-model:value="dataSource.amount"
          size="large"
          style="width: 100%"
          type="number"
          :required="true"
          step="0.01"
        >
          <template #addonAfter> <p>USDT</p> </template>
        </a-input>
      </a-form-item>
      <div class="create-account__buttons">
        <sdButton
          class="action__button"
          size="large"
          type="primary"
          :disabled="isLoading || errorAmount"
          htmlType="submit"
          raised
        >
          {{ i18n.t('start.buy') }}
        </sdButton>
      </div>
    </a-form>
  </sdModal>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          visible: false,
          data: null,
        };
      },
    },
  },
  emits: ['dataNotSended', 'dataSended'],

  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const i18n = reactive(useI18n());
    // const router = useRouter();

    const errorAmount = ref(false);
    const error = computed(() => state.shop.error);
    const isLoading = computed(() => state.shop.loading);
    const offersOptions = computed(() => state.shop.offersOptionsData);
    const dataSource = ref({ amount: 0, kind: 2, item_type: null });

    const amountValidation = () => {
      if (!rules.amount[0].trigger[1]) {
        rules.amount[0].trigger[1] = 'change';
      }
      const validate = { min: 10, max: 99999999 };
      if (validate.min > dataSource.value.amount || validate.max < dataSource.value.amount) {
        errorAmount.value = true;
        return Promise.reject(
          i18n.t('messages.errorAmount', validate, dataSource.value.amount > validate.max ? 2 : 1) + ' USDT',
        );
      } else {
        errorAmount.value = false;
        return Promise.resolve();
      }
    };

    const rules = reactive({
      amount: [
        {
          required: true,
          trigger: ['blur'],
          type: 'number',
          validator: amountValidation,
        },
      ],
      item_type: [
        {
          required: true,
          message: i18n.t('option.error.itemTypeRequired'),
          trigger: 'blur',
          type: 'number',
        },
      ],
    });

    const closeModal = () => {
      dispatch('clearPublicErrors');
      emit('update:data', { visible: false, data: props.data.data });
    };

    const sendData = async () => {
      await dispatch('purchaseOptionInShop', dataSource.value);
      if (!error.value) {
        emit('dataSended');
        closeModal();
      }
      return;
    };

    dispatch('getOffersOptions');

    return {
      i18n,
      closeModal,
      dataSource,
      offersOptions,
      sendData,
      errorAmount,
      isLoading,
      rules,
      error,
    };
  },
};
</script>
